import axios from "axios";

export default {
    namespaced: true,

    state: () => ({
        partnersList: [],
        expertsList: [],
        partner: null,
        services: null,
        formats: null,
        cities: null,
        partners: []
    }),

    mutations: {
        setPartnersList(state, data) {
            state.partnersList = data;
        },
        setExpertsList(state, data) {
            state.expertsList = data;
        },
        setPartner(state, data) {
            state.partner = data;
        },
        setPartners(state, data) {
            state.partners = data;
        },
        setFormats(state, data) {
            state.formats = data;
        },
        setServices(state, data) {
            state.services = data;
        },
        setCities(state, data) {
            state.cities = data
        }
    },

    actions: {
        async getPartner({ commit }, id) {
            await axios
                .get(process.env.VUE_APP_LK_BACK + `/api/public/partners/${id}`)
                .then(({ data }) => {
                    commit('setPartner', data)
                }).catch(e => {
                    console.log(e)
                })
        },
        async getExpert({ commit }, id) {
            await axios
                .get(process.env.VUE_APP_LK_BACK + `/api/public/partners/experts/${id}`)
                .then(({ data }) => {
                    commit('setPartner', data)
                }).catch(e => {
                    console.log(e)
                })
        },
        async getPartnersList({ commit }) {
            await axios
                .get(process.env.VUE_APP_API_URL + `/partners/list?only=name,id,teachers,city,middle_image`)
                .then(({ data }) => {
                    commit('setPartnersList', data.data)
                }).catch(e => {
                    console.log(e)
                })
        },
        async getExpertsList({ commit }) {
            await axios
                .get(process.env.VUE_APP_API_URL + `/teachers/list?only=id,name,addres,middle_image,city,rating`)
                .then(({ data }) => {
                    commit('setExpertsList', data.data)
                }).catch(e => {
                    console.log(e)
                })
        },
        async getPartners({ commit }) {
            await axios
                .get(process.env.VUE_APP_LK_BACK + `/api/public/partners`)
                .then(({ data }) => {
                    commit('setPartners', data)
                }).catch(e => {
                    console.log(e)
                })
        },
        async getFormats({ commit }) {
            await axios
                .get(process.env.VUE_APP_LK_BACK + `/api/public/partners/job_formats`)
                .then(({ data }) => {
                    commit('setFormats', data)
                }).catch(e => {
                    console.log(e)
                })
        },
        async getCities({ commit }) {
            await axios
                .get(process.env.VUE_APP_LK_BACK + `/api/public/partners/cities`)
                .then(({ data }) => {
                    commit('setCities', data)
                }).catch(e => {
                    console.log(e)
                })
        },
        async getServices({ commit }) {
            await axios
                .get(process.env.VUE_APP_LK_BACK + `/api/public/partners/services`)
                .then(({ data }) => {
                    commit('setServices', data)
                }).catch(e => {
                    console.log(e)
                })
        },
    }
}