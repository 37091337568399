<template>
  <div class="modal-backdrop" @mousedown.self="closeModal">
    <div class="popup-content display--flex">
      <div class="__inner display--flex">
        <div class="popup-left">
          <div class="popup-logo">
            <img
              src="@/assets/images/logo.svg"
              alt="WAY2WEI"
              class="for-index hidden-sm"
            />
          </div>
        </div>
        <div class="popup-right">
          <div class="popup-body display--flex">
            <div class="popup-logo">
              <img
                src="@/assets/images/logo-b.svg"
                alt="WAY2WEI"
                class="visible-xs"
              />
            </div>
            <button type="button" class="popup-close" @click="closeModal">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M4 4L12 12M20 20L12 12M12 12L20 4M12 12L4 20"
                  stroke="currentColor"
                  stroke-linecap="round"
                />
              </svg>
            </button>
              <slot>
                  <div class="popup-container">
                      <div class="popup-title">{{title}}</div>
                  </div>
              </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { onMounted } from "@vue/runtime-core";

export default {
  components: { },
  name: "popup-technical-work",
  props: {
    title: {
      type: String,
    },
  },
  setup(props, context) {

    const closeModal = () => {
      document.getElementsByTagName("html")[0].style.overflow = "unset";
      context.emit("closeModal");
    };

    onMounted(() => {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    });


    return {
      closeModal
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-y: auto;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.additional-info {
  font-size: 0.9rem;
  line-height: 1.5rem;
  margin: 10px;
}

.popup-container {
  margin-right: 35px;
}

.popup-content {
  width: 80%;
  min-height: 80%;
  background-color: var(--color-light);
}

.popup-content .__inner {
  flex: 1;
}

.popup-left {
  position: relative;
  flex: 1;
  background: linear-gradient(180deg, #313554 0%, #3e4262 100%);
}

.popup-left::before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./../../assets/images/hero-green.svg);
  background-repeat: no-repeat;
  background-position: left bottom -10px;
  background-size: 81vw auto;
}

.popup-logo {
  position: absolute;
  display: block;
  top: 50px;
  left: 132px;
  width: 555px;
  height: 40px;
  text-align: left;
}

.popup-right {
  flex: 1;
  background-image: url(./../../assets/images/hero.svg);
  background-repeat: no-repeat;
  background-position: right -16px bottom -11px;
  background-size: 81vw auto;
}

.popup-body {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 600px;
  height: 100%;
  padding: 50px 0 50px 70px;
  font-size: 28px;
  line-height: 34px;
}

.popup-body p {
  margin: 0 0 30px 0;
}

.popup-close {
  position: absolute;
  display: flex;
  top: 54px;
  right: 32px;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  padding: 0;
  color: var(--color);
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

.popup-close:hover {
  color: var(--accent);
}

.popup-close svg {
  width: 100%;
  height: 100%;
}

.popup-title {
  margin-bottom: 30px;
  font-size: 46px;
  line-height: 51px;
}



@media (max-width: 1024px) {
  .popup-left {
    display: none;
  }
}

@media (max-width: 768px) {
  .popup-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .popup-body form .form-group + .form-action {
    margin-top: 25px;
  }

  .popup-logo {
    top: 32px;
    left: 32px;
  }

  .popup-container {
    margin-right: 0;
    padding-top: 73px;
    height: 100%;
  }

  .popup-close {
    top: 32px;
    right: 32px;
    width: 32px;
    height: 32px;
  }

  .popup-body {
    align-items: center;
    width: 100%;
    padding: var(--xs-continer-gap);
  }

  .popup-title {
    font-size: 36px;
    line-height: 43px;
  }

  .space {
    height: 15px;
  }
}

</style>