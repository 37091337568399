import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import("./../views/main-page/MainPage"),
  },
  {
    path: '/contacts',
    component: () => import("./../views/about-command-page/AboutCommandPage")
  },
  {
    path: '/media-center',
    component: () => import("./../views/media-pages/MediaPage")
  },
  {
    path: '/diagnostic',
    component: () => import("./../views/diagnostic-page/DiagnosticPage")
  },
  {
    path: '/methodology',
    component: () => import("./../views/methodology-page/MethodologyPage"),
  },
  {
    name: "catalog",
    path: '/catalog',
    component: () => import("./../views/catalog-page/CatalogPage")
  },
  {
    path: '/geography',
    component: () => import("./../views/partners-navigator-page/PartnersNavigatorPage")
  },
  {
    path: '/product/:slug',
    alias: ['/complex/:slug'],
    name: 'product-page',
    component: () => import("./../views/product-page/ProductPage")
  },
  {
    path: '/cart',
    component: () => import("./../views/cart-page/CartPage")
  },
  {
    path: '/partner/:id',
    component: () => import("./../views/partner-page/PartnerPage")
  },
  {
    path: '/expert/:id',
    component: () => import("./../views/partner-page/ExpertPage")
  },
  {
    name: 'blog',
    path: '/register/stati/:id?',
    component: () => import("./../views/media-pages/BlogPage")
  },
  {
    path: '/products/:name',
    component: () => import("./../views/products-page/ProductsPage")
  },
  {
    path: '/news/:slug',
    component: () => import("./../views/media-pages/NewsPage")
  },
  {
    path: '/document/privacy_policy',
    component: () => import('./../views/documents/PrivacyPolicy')
  },
  {
    path: '/document/offer',
    component: () => import('./../views/documents/Offer')
  },
  {
    path: '/document/consent',
    component: () => import('./../views/documents/Consent')
  },
  {
    path: '/document/payment',
    component: () => import('./../views/documents/Payment')
  },
  {
    path: '/document/info',
    component: () => import('./../views/documents/Information')
  },
  {
    path: '/document/documentation',
    component: () => import('./../views/documents/Documentation')
  },
  {
    path: '/document/mailing',
    component: () => import('./../views/documents/Advert')
  },
  {
    path: '/order',
    component: () => import("./../views/order-page/OrderPage")
  },
  {
    path: '/404',
    name: '404',
    component: () => import('./../views/not-found/NotFound')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('./../views/not-found/NotFound')
  },
  {
    path: '/success-payment/:id/:secret_code',
    component: () => import('./../views/order-page/SuccessPaymentPage')
  },
  {
    path: '/fail-payment',
    component: () => import('./../views/order-page/FailPayment')
  },
  {
    path: '/testing',
    component: () => import("./../views/testing-page/TestingPage")
  },
  {
    path: '/testing-result',
    component: () => import("./../views/testing-page/TestingResultPage")
  },
  {
    path: '/conditions-distribution',
    component: () => import("./../views/documents/ConditionsDistribution.vue")
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
