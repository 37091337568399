import axios from "axios";

export default {
    namespaced: true,

    state: () => ({
        news: null,
        newsTotalCount: null,
        singleNews: null,
        randomNews: null
    }),

    mutations: {
        setNewsList(state, data) {
            state.news = data
        },
        setSingleNews(state, data) {
            state.singleNews = data
        },
        setRandomNews(state, data) {
            state.randomNews = data
        },
        setNewsTotal(state, data) {
            state.newsTotalCount = data
        }
    },

    getters: {
        news: state => state.news,
        singleNews: state => state.singleNews,
        randomNews: state => state.randomNews,
        newsTotalCount: state => state.newsTotalCount
    },

    actions: {
        async getNews({ commit }, pageInfo) {
            await axios.get(`${process.env.VUE_APP_API_URL}/news/list?only=id,title,slug,tags,image,middle_image,full_image&order=created_at,DESC&per_page=${pageInfo.perPage}&page=${pageInfo.page}`)
                .then(res => {
                    commit('setNewsList', res.data.data)
                    commit('setNewsTotal', res.data.meta.total)
                })
                .catch(err => console.log(err))
        },
        async getSingleNews({ commit }, slug) {
            await axios.get(process.env.VUE_APP_API_URL + `/news/${slug}/get/slug`)
                .then(res => commit('setSingleNews', res.data.data))
                .catch(err => console.log(err))
        },
        async getRandomNews({ commit }, newId) {
            await axios.get(process.env.VUE_APP_API_URL + `/news/random/list?per_page=1&ignore=${newId}`)
                .then(res => commit('setRandomNews', res.data.data[0]))
                .catch(err => console.log(err))
        },
        async getNewsByTag({ commit }, tag) {
            await axios.get(`${process.env.VUE_APP_API_URL}/news/list/?only=id,title,slug,middle_image,tags,image&tags=${tag}`)
                .then(res => {
                    commit('setNewsList', res.data.data)
                    commit('setNewsTotal', res.data.meta.total)
                })
                .catch(err => console.log(err))
        }
    }
}