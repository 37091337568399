import { createStore } from "vuex";
import axios from "axios";

import products from './products/index'
import news from './news/index'
import blog from './blog/index'
import user from './user/index'
import partners from './partners/index'

export default createStore({
  state: {
    clients: [],
    staff: null,
    testProducts: [],
    testPdf: null
  },
  mutations: {
    setClients(state, data) {
      state.clients = data;
    },
    setStaff(state, data) {
      state.staff = data
    },
    setTestProducts(state, data) {
      state.testProducts = data
    },
    setTestPdf(state, data) {
      state.testPdf = data
    },
    setYmUid(state, data) {
      state.user = data
      localStorage.setItem('user', JSON.stringify(data));
    }
  },
  getters: {
    clients: state => state.clients,
    staff: state => state.staff
  },
  actions: {
    async getClients({ commit }) {
      await axios
        .get(process.env.VUE_APP_API_URL + "/clients/list")
        .then(({ data }) => {
          commit("setClients", data.data)
        })
        .catch((error) => console.log("error", error));
    },
    // eslint-disable-next-line no-unused-vars
    async sendForm({ commit }, request) {
      let utm = document.cookie.match(new RegExp("utm_query=([^;]*)"))
      if (utm) request.utm = utm[1]
      await axios
        .post(process.env.VUE_APP_API_URL + "/actions/help", request)
    },
    // eslint-disable-next-line no-unused-vars
    async sendDemoRequest({ commit }, request) {
      let utm = document.cookie.match(new RegExp("utm_query=([^;]*)"))
      if (utm) request.utm = utm[1]
      await axios.post(process.env.VUE_APP_API_URL + '/actions/demo', request)
    },
    // eslint-disable-next-line no-unused-vars
    async sendSubscribeRequest({ commit }, request) {
      let utm = document.cookie.match(new RegExp("utm_query=([^;]*)"))
      if (utm) request.utm = utm[1]
      await axios.post(process.env.VUE_APP_API_URL + '/actions/subscribe', request)
    },
    // eslint-disable-next-line no-unused-vars
    async sendPartnerRequest({ commit }, request) {
      let utm = document.cookie.match(new RegExp("utm_query=([^;]*)"))
      if (utm) request.utm = utm[1]
      await axios.post(process.env.VUE_APP_API_URL + '/actions/partner-submit', request)
    },
    // eslint-disable-next-line no-unused-vars
    async sendExpertRequest({ commit }, request) {
      let utm = document.cookie.match(new RegExp("utm_query=([^;]*)"))
      if (utm) request.utm = utm[1]
      await axios.post(process.env.VUE_APP_API_URL + '/actions/teacher-submit', request)
    },
    async getStaff({ commit }) {
      await axios
        .get(process.env.VUE_APP_API_URL + "/staffs/list")
        .then(({ data }) => {
          commit("setStaff", data.data)
        })
        .catch((error) => console.log("error", error));
    },
    async setYmUid({ commit }) {
      let userData = JSON.parse(localStorage.getItem('user'))
      if (userData.ya_metric_id === '') {
        let ymUid = document.cookie.match(new RegExp('(?:^|;)\\s*_ym_uid=([^;]*)'));
        if (ymUid) {
          await  axios.post(process.env.VUE_APP_AUTH_URL + '/user/' + userData.id + '/metric',
              {id: ymUid[1]})
              .then(({ data }) => {
                commit("setYmUid", data.user)
              })
        }
      }
    },
    async getTestProductsByScore({ commit }, score) {
      await axios
        .get(process.env.VUE_APP_API_URL + `/products/test-ei/${score}`)
        .then(({ data }) => {
          commit("setTestProducts", data.data)
        })
        .catch((error) => console.log("error", error));
    },
    async sendTestDataToGeneratePdf({ commit }, data) {
      let request = {
        image: data.image,
        score_total: data.sum,
        email: data.email
      }
      // Генерируем полея score_*
      data.scores.forEach((score, index) => {
        request[`score_${index + 1}`] = score
      })
      return await axios
        .post(process.env.VUE_APP_API_URL + `/pdf/generate-by-test`, request)
        .then(({ data }) => {
          commit("setTestPdf", data.filename)
        })
    },
    // eslint-disable-next-line no-unused-vars
    async sendTestForm({ commit }, data) {
      return await axios
        .post(process.env.VUE_APP_API_URL + `/tests/send`, data)
    }
  },
  modules: {
    products, news, blog, user, partners
  },
});
