import { ref } from "vue"

export default function () {

    let errors = ref(null)

    let setErrors = (newErrors) => {
        errors.value = newErrors
        for (let field in errors.value) {
            let inputField = document.querySelector(`input[error=${field}]`)
            if (inputField && inputField != "telephone") {
                inputField.classList.add('error')
            }
            else if (field == "telephone") {
                let telField = document.querySelector(`div[error=${field}]`)
                if (telField) {
                    telField.classList.add('error')
                }   
            }
        }
    }
    let clearErrors = () => {
        for (let field in errors.value) {
            let inputField = document.querySelector(`input[error=${field}]`)
            if (inputField && field != "phone") {
                document.querySelector(`input[error=${field}]`).classList.remove('error')
            }
            else if (field == "phone") {
                let telField = document.querySelector(`div[error=${field}]`)
                if (telField) {
                    telField.classList.remove('error')
                }
            }
        }
        errors.value = null;
    }
    let clearCustomErrors = () => {
        setTimeout(() => {
            clearErrors();
        }, 3000)
    }

    return {
        errors,
        setErrors,
        clearErrors,
        clearCustomErrors
    }


}