<template>
  <div class="checkboxes">
    <div class="checkbox">
      <input id="oferta" type="checkbox" v-model="checkboxes.oferta"/>
      <label for="oferta">Согласен с
        <router-link to="/document/offer">офертой</router-link>
        *</label>
    </div>
    <div class="checkbox">
      <input
          @input="checkboxChange"
          id="personalData"
          type="checkbox"
          v-model="checkboxes.personalData"
      />
      <label for="personalData">Согласен с
        <router-link to="/document/privacy_policy">политикой обработки персональных данных
        </router-link>
        *
      </label>
    </div>
    <div class="checkbox">
      <input
          @input="checkboxChange"
          id="ad"
          type="checkbox"
          v-model="checkboxes.ad"
      />
      <label for="ad">Согласен на получение
        <router-link to="/document/mailing">рекламной рассылки</router-link>
      </label>
    </div>
  </div>
</template>

<script>
import {reactive} from "@vue/reactivity";

export default {
  name: "private-checkboxes",
  setup(props, context) {
    const checkboxes = reactive({
      oferta: false,
      personalData: false,
      ad: false,
    });

    const checkboxChange = () => {
      context.emit("checkboxchange", checkboxes);
    };

    return {
      checkboxes,
      checkboxChange,
    };
  },
};
</script>

<style scoped lang="scss">
.checkboxes {
  font-size: 1.05rem;
  word-spacing: 0.9%;
  margin: 15px 0 9px 0;

  label {
    padding-left: 26px;
    position: relative;
  }

  label::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    margin-right: 10px;
  }

  input {
    height: 0;
    width: 0;
  }

  input:checked ~ label::after {
    content: "";
    background-image: url("./../../../assets/images/icons/check.svg");
    background-size: cover;
    position: absolute;
    top: 3px;
    left: 0.5px;
    height: 19px;
    width: 19px;
    display: inline-block;
  }

  a {
    text-decoration: underline;
  }
}
</style>