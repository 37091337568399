export default (type) => {
	const goalTypes = {
		"ConsultMain" : 'consultation_main',
		"ConsultTasks": 'consultation_main',
		"ConsultProduct": 'product_consultation',
		"ConsultFooter": 'consultation_free_futer',
		"Demo": 'demo_platforma',
		"new_partner": 'new_partner',
		"Diagnostic": 'diagnostic_kit',
		"pdfDownloaded": 'form-pdf-download',
		"subscription": 'subscription',
		"order": 'order',
	}

	return goalTypes[type] ?? '';
}