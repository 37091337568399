<template>
  <div class="error-wrap" v-if="currentErrors" ref="errorElement">
    <div v-if="currentField && checkError">
      <p v-for="(error, i) in fieldError" :key="i">{{ error }}</p>
    </div>
    <div v-if="!currentField">
      <p v-for="(error, i) in currentErrors" :key="i">{{ printArray }}</p>
    </div>
  </div>
</template>

<script>
import { computed, ref, toRef } from "@vue/runtime-core";

export default {
  name: "errors",
  props: ["errors", "field"],
  setup(props) {
    let errorElement = ref(null);
    let currentErrors = toRef(props, "errors"),
      currentField = toRef(props, "field");

    let checkError = computed(() =>
        Object.keys(currentErrors.value).find((error) => error == currentField.value)
      ),
      fieldError = computed(() => currentErrors.value[checkError.value]),
      printArray = computed(() => {
        if (
          typeof currentErrors.value != "undefined" &&
          currentErrors.value != null &&
          currentErrors.value.length > 0 &&
          typeof currentErrors.value == "object"
        ) {
          return currentErrors.value.join(", ");
        }
        return currentErrors.value;
      });

    return {
      checkError,
      fieldError,
      printArray,
      errorElement,
      currentErrors,
      currentField,
    };
  },
};
</script>

<style scoped lang="scss">
.error-wrap {
  z-index: 8;

  p {
    color: #959ABB;
    margin-top: 4px;
    font-size: 14px;
    line-height: 16.8px;
  }
}
</style>
